import { useContext } from 'react';
import { RecordListContext } from '../contexts';

export const useRecordListContext = () => {
  const context = useContext(RecordListContext);

  if (!context) {
    throw new Error('useRecordListContext must be used within a RecordListContextProvider');
  }

  return context;
};

export default useRecordListContext;
