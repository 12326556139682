import SideBar from './components/Sidebar';
import { DocumentViewer, DocumentProvider } from '../../features/documents';
import { RecordListModal, RecordListProvider, RecordProvider } from '../../features/records';

const DashboardView = () => {
  return (
      <RecordListProvider>
        <RecordProvider>
          <DocumentProvider>
            <div style={{ height: '100vh' }} className='flex overflow-hidden no-wrap'>
              <div style={{ flex: '0 0 300px' }} className='h-screen overflow-y-auto'>
                <SideBar />
                <RecordListModal />
              </div>
              <div style={{ flex: '1 1 auto', background: '#F2F4FA' }}>
                <DocumentViewer />
              </div>
            </div>
          </DocumentProvider>
        </RecordProvider>
      </RecordListProvider>
  );
};

export default DashboardView;
