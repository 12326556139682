import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { useObjectTypes } from '../hooks';
import { mapObjectTypeToDropdownOption } from '../utils';
import { CustomSchemaType } from '../../../app/api/schemas';
import { SelectItem } from 'primereact/selectitem';
import { HiOutlineUser } from 'react-icons/hi';
import useObject from '../../../hooks/useObject';
import _ from 'lodash';

type ObjectTypeDropdownProps = Omit<DropdownProps, 'options' | 'value'>;

type optionGroupProperties = {
  label: string;
  code: string;
  items: SelectItem;
};

const STANDARD_OBJECT_TYPES = ['CONTACT', 'COMPANY', 'DEAL', 'TICKET'];

function isStandardObjectType(objectTypeName: string) {
  return STANDARD_OBJECT_TYPES.includes(objectTypeName);
}

export default function ObjectTypeDropdown(props: ObjectTypeDropdownProps) {
  const { className, filter = false, ...rest } = props;
  const { objectType, setObject } = useObject();
  const { objectTypes, selectedObjectType } = useObjectTypes();
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedObjectType) {
      setValue(objectType);
    } else {
      setValue(selectedObjectType.objectTypeId);
    }
  }, [objectType, setValue, selectedObjectType]);

  // Options for dropdown menu.
  const options = useMemo(() => {
    const { data } = objectTypes;

    if (!data) {
      return [
        {
          label: 'HubSpot',
          code: 'hubspot',
          items: [{ value: objectType, label: _.startCase(objectType?.toLowerCase() || '') }],
        },
      ];
    }

    const hubspotObjects = data?.filter(({ objectTypeName }: CustomSchemaType) => isStandardObjectType(objectTypeName));
    const customObjects = data?.filter(({ objectTypeName }: CustomSchemaType) => !isStandardObjectType(objectTypeName));

    return [
      {
        label: 'Standard Objects',
        code: 'hubspot',
        items: hubspotObjects?.map(mapObjectTypeToDropdownOption),
      },
      {
        label: 'Custom Objects',
        code: 'custom',
        items: customObjects?.map(mapObjectTypeToDropdownOption),
      },
    ];
  }, [objectTypes, objectType]);

  const handleChange = useCallback(
    (objectTypeId: string) => {
      setObject({ objectType: objectTypeId, objectId: null });
    },
    [setObject],
  );

  const groupedItemTemplate = (option: optionGroupProperties) => {
    return (
      <div className='-ml-2 flex items-center gap-2 w-full h-full bg-transparent'>
        {option.code === 'hubspot' ? (
          <img
            src='./images/hubspot-logo.svg'
            alt='hs logo'
            style={{ width: 16, color: 'red' }}
            title='HubSpot Defined'
          />
        ) : (
          <HiOutlineUser className='w-[25px] h-[25px] text-slate-600' />
        )}
        <p className='font-bold text-md text-slate-600 whitespace-nowrap'>{option.label}</p>
      </div>
    );
  };

  return (
    <Dropdown
      options={options}
      optionValue='value'
      optionLabel='label'
      optionGroupLabel='label'
      optionGroupChildren='items'
      optionGroupTemplate={groupedItemTemplate}
      value={value}
      filter={filter}
      loading={!objectType && objectTypes.isLoading}
      dropdownIcon={(a) => <i className='pi pi-sort-down-fill text-slate-500'></i>}
      //@ts-ignore
      // We have to remove this for time because we downgraded primereact to 10.8.2 to allow token copying.
      // collapseIcon={() => <i className='pi pi-sort-up-fill'></i>}
      onChange={(e) => handleChange(e.target.value)}
      className={`p-inputtext-sm ${className}`}
      {...rest}
    />
  );
}
