import { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';

interface ControlledSearchButtonInputProps {
  value: string;
  handleValueChange: (searchValue: string) => void;
}
export default function ControlledSearchButtonInput({ value, handleValueChange }: ControlledSearchButtonInputProps) {
  const [showInput, setShowInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    setShowInput((cur) => !cur);
  };

  // Used as a work-around because focusing from event handler doesn't work
  useEffect(() => {
    if (showInput || value?.length > 0) inputRef.current?.focus();
  }, [showInput, value]);

  const inputStyle = { padding: '0.25rem 0px 0.4rem 40px', minWidth: '0px', width: '30px' };

  if (showInput || value?.length > 0) inputStyle.width = '200px';

  const setChangedValue = useCallback(
    (searchValue: string) => {
      handleValueChange(searchValue);
    },
    [handleValueChange],
  );

  return (
    <div>
      <span className='p-input-icon-left p-icon-'>
        <i className='pi pi-search cursor-pointer ml-3' onClick={handleButtonClick} />
        <InputText
          placeholder='Search'
          value={value}
          style={inputStyle}
          ref={inputRef}
          onChange={(e) => setChangedValue(e.target.value)}
          className={showInput ? '' : 'cursor-pointer'}
          onFocus={() => setShowInput(true)}
        />
        {showInput && value !== '' && (
          <i className='pi pi-times cursor-pointer -ml-8 pi-small' onClick={() => setChangedValue('')} />
        )}
      </span>
    </div>
  );
}
