import { ExtendedProperty } from './mappers';

export function snakeCaseToTitleCase(str: string) {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function CapitalizeFirstCharacter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Divides properties into primary and associated properties
export function splitPrimaryAndAssociatedProperties(properties: ExtendedProperty[]): {
  primary: ExtendedProperty[];
  associated: ExtendedProperty[];
} {
  const primary = properties.filter((prop) => !prop.relatedObjectProperties);
  const associated = properties.filter((prop) => prop.relatedObjectProperties);
  return { primary, associated };
}
