/**
 * Schemas provide the structure for public and custom objects.
 * Schemas provide the name (type), type ID, primaryDisplayProperty, and secondaryDisplayProperties, and many other properties.
 */

import { apiSlice } from '.';
import type { ListResponse } from './types';
import { transformListResponse, transformSingleResponse } from './utils';

// Options are based of the Hubspot's schema data. Curretly only string, bool, datetime, number, are supported.
export type PropertyTypeOptions = 'string' | 'bool' | 'datetime' | 'number';

// Properties require a name, label, and type to be displayed on the client.
export interface DisplayPropertiesType {
  name: string;
  label: string;
  default: boolean;
  type: PropertyTypeOptions;
}

export interface CustomSchemaType {
  objectTypeId: string;
  objectTypeName: string;
  primaryDisplayProperty: string;
  properties: DisplayPropertiesType[];
  singularLabel: string;
  pluralLabel: string;
}

const SCHEMA_URL = 'schemas';

export const schemasApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSchemas: builder.query<CustomSchemaType[], void>({
      query: () => `/${SCHEMA_URL}`,
      providesTags: (response, error: any) => {
        if (error) throw new Error(error.message);
        if (response) {
          return [
            { id: 'LIST', type: 'Schemas' as const },
            ...response.map(({ objectTypeId: id }) => ({ id, type: 'Schemas' as const })),
          ];
        }
        return [{ id: 'LIST', type: 'Schemas' as const }];
      },
      transformResponse: (response: ListResponse<CustomSchemaType>) => transformListResponse(response).data,
    }),

    // Not used currently
    // Return all custom (HubSpot user defined) object schemas.
    getCustomObjectSchemas: builder.query<CustomSchemaType[], void>({
      query: () => `/${SCHEMA_URL}/custom-objects`,
      providesTags: (response, error: any) => {
        if (error) throw new Error(error.message);
        if (response) {
          return [
            { id: 'LIST', type: 'CustomObjectSchemas' as const },
            ...response.map(({ objectTypeId: id }) => ({ id, type: 'CustomObjectSchemas' as const })),
          ];
        }
        return [{ id: 'LIST', type: 'CustomObjectSchemas' as const }];
      },
      transformResponse: (response: ListResponse<CustomSchemaType>) => transformListResponse(response).data,
    }),

    getSchema: builder.query<CustomSchemaType, string>({
      query: (objectTypeId) => `/${SCHEMA_URL}/${objectTypeId}`,
      providesTags: ['Schema'],
      transformResponse: transformSingleResponse<CustomSchemaType>,
    }),
  }),
});

export const {
  useGetSchemaQuery,
  useGetCustomObjectSchemasQuery,
  useLazyGetCustomObjectSchemasQuery,
  useLazyGetSchemaQuery,
  useGetAllSchemasQuery,
} = schemasApi;
