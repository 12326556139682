import type { ExtendedProperty } from '../../utils';

const renderDefinedByColumnBody = (property: ExtendedProperty) => (
  <div className='flex justify-center'>
    {property.isCustom ? (
      <i className='pi pi-user' title='User Defined' />
    ) : (
      <img
        src='./images/hubspot-logo.svg'
        alt='hs logo'
        style={{ maxWidth: '20px', filter: 'grayscale()' }}
        title='HubSpot Defined'
      />
    )}
  </div>
);

export default renderDefinedByColumnBody;
