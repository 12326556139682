import { useCallback, useRef } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useDocument } from '../hooks';

export interface GenerateDocumentButtonProps {
  templateId: string | null;
  objectType: string | null;
  recordId: string | null;
  preview?: boolean;
}

type GenerateDocumentProps = ButtonProps & GenerateDocumentButtonProps;

export interface CreateDocumentArgs {
  templateId: string;
  objectType: string;
  recordId: string;
  preview?: boolean;
}

const GenerateDocumentButton = (props: GenerateDocumentProps) => {
  const { templateId, objectType, recordId, label = 'Generate', preview, ...rest } = props;
  const { createDocument, documentMeta } = useDocument();
  const toast = useRef<Toast>(null);

  const generateDocument = useCallback(
    async ({ objectType, recordId, templateId, preview }: CreateDocumentArgs) => {
      try {
        const isPreview = Boolean(preview);

        const response = await createDocument({ templateId, objectType, recordId, isLive: !isPreview });

        if (preview) return;

        if ('error' in response) throw new Error();
        if (!response.data.name) throw new Error();

        toast.current?.show({
          severity: 'success',
          summary: 'Document Generated',
          detail: (
            <>
              <b>{response.data.name}</b> has been saved to the <b>Notes</b> section of this record.
            </>
          ),
          life: 5000,
        });
      } catch (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error was encountered while generating the document.',
          life: 3000,
        });
      }
    },
    [createDocument],
  );

  return (
    <>
      <Button
        disabled={documentMeta?.isLoading || !objectType || !recordId || !templateId}
        label={label}
        onClick={() => {
          if (!templateId || !objectType || !recordId) return;
          generateDocument({ objectType, recordId, templateId, preview });
        }}
        tooltipOptions={{ showDelay: 1000, position: 'left' }}
        icon='pi pi-file-export'
        iconPos='right'
        loading={documentMeta?.isLoading}
        {...rest}
      />
      <Toast ref={toast} position='bottom-right' />
    </>
  );
};

export default GenerateDocumentButton;
