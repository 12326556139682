import React from 'react';
import formatters from '../../utils/formatters';
import { ExtendedProperty } from '../../utils/mappers';
import { useTokensViewContext } from '../../hooks';
import { Tooltip } from 'primereact/tooltip';
import InfoIcon from '../../../../components/InfoIcon';

export function TokenTableColumnBody({ property }: { property: ExtendedProperty }) {
  const { isFormatted, useType, tokensView } = useTokensViewContext();
  const { key, relatedObjectProperties, parent, name, fieldType, type } = property || {};
  const iconKey = `${key}-1`;

  // This will notify us if parent properties are being used
  if (relatedObjectProperties?.length)
    throw new Error('Parent properties are not supported in this TokenTableColumnBody.tsx component');

  let tokenValue = '';
  if (tokensView === 'primary' || useType === 'loop') {
    tokenValue = name;
  } else {
    tokenValue = `${parent?.name}.0.${name}`;
  }

  const formatter = fieldType ? formatters[`${type}:${fieldType}`] : undefined;
  if (formatter && isFormatted) tokenValue = formatter(tokenValue);
  tokenValue = `{{${tokenValue}}}`;

  const tooltip =
    useType === 'loop' && !relatedObjectProperties?.length
      ? `Paste this inside element(s) looping over '${parent?.name}'`
      : 'Paste this token into your documint template';

  return (
    <>
      <div className='flex'>
        {tokenValue && (
          <div className='flex items-center'>
            <code className='bg-slate-100 border border-slate-200 py-0.5 px-2 rounded break-keep text-sm mx-0.5'>
              {tokenValue}
            </code>
            <InfoIcon id={iconKey} tooltip={tooltip} />
          </div>
        )}
      </div>

      <Tooltip target={`#${iconKey}`} className='z-1000' />
    </>
  );
}

export default TokenTableColumnBody;
