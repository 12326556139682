import { apiSlice } from '.';

export interface AuthorizationUrlResponse {
  url: string;
}

export type TokenValidityResponse = {
  message: string;
  status: 'success' | 'failed';
  missingScopes?: string[];
  hasRequiredScopes: boolean;
  error?: string;
  authorizationUrl?: string;
};

export const hubspot = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Check access token validity
    checkTokenValidity: builder.query<TokenValidityResponse, void>({
      query: () => '/hubspot/token-validity',
    }),

    // Get authorization URL to display app in the frontend
    getAuthorizationUrl: builder.query<AuthorizationUrlResponse, void>({
      query: () => '/hubspot/authorization-url',
      providesTags: ['AuthorizationUrl'],
    }),
  }),
});

export const { useCheckTokenValidityQuery, useLazyCheckTokenValidityQuery } = hubspot;
