import { useMemo } from 'react';
import { useGetAllSchemasQuery } from '../../../app/api/schemas';
import useObject from '../../../hooks/useObject';

export function useObjectTypes() {
  const schemas = useGetAllSchemasQuery();
  const { objectType } = useObject();

  const selectedObjectType = useMemo(() => {
    return (
      schemas.data?.find(
        ({ objectTypeId, objectTypeName }) => objectTypeId === objectType || objectTypeName === objectType,
      ) || null
    );
  }, [objectType, schemas]);

  return useMemo(() => ({ objectTypes: schemas, selectedObjectType }), [schemas, selectedObjectType]);
}

export default useObjectTypes;
