import { useEffect, useRef, useState } from 'react';
import ApiKeyForm from './ApiKeyForm';
import { Image } from 'primereact/image';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useApiKey } from '../../features/auth';

const ApiKeyView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const { get, getMeta, validate, validateMeta } = useApiKey();

  const data = getMeta?.data?.data;
  const apiKeyPrefix = getMeta?.data?.data?.apiKeyPrefix;
  const hasApiKey = !!apiKeyPrefix;

  const isValid = validateMeta?.data?.isValid;

  const [isEditMode, setIsEditMode] = useState<boolean>(true);

  useEffect(() => {
    // get the api key on load
    if (getMeta.isUninitialized) {
      get();
    }
  }, [get, getMeta]);

  useEffect(() => {
    hasApiKey && validate();
  }, [validate, hasApiKey]);

  useEffect(() => {
    if (!getMeta.isLoading && data?.apiKeyPrefix) setIsEditMode(false);
  }, [getMeta, data]);

  const handleFinish = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success!',
      detail: 'Api key added successfully.',
      life: 3000,
    });
    setIsEditMode(false);
    if (state?.redirectTo) navigate(state.redirectTo);
  };

  const handleError = (error: any) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error!',
      detail: error?.data?.message,
      life: 3000,
    });
  };

  function renderEditMode() {
    return (
      <div className='flex flex-col min-w-lg max-w-xl items-center gap-5 py-5 justify-center'>
        <h1 className='text-xl mb-3 text-center text-slate-600'>Paste your Documint API Key</h1>
        <ApiKeyForm
          onFinish={handleFinish}
          onCancel={() => setIsEditMode(false)}
          onError={handleError}
          cancellable={hasApiKey}
        />
        <CreateApiKeyLink className='p-button p-button-link' />
      </div>
    );
  }

  function renderDisplayMode() {
    return (
      <div className='flex flex-col items-center justify-around gap-6 flex-auto '>
        <p>
          <span className='rounded-lg bg-neutral-100 px-3 py-2 ml-3 tracking-wider font-mono text-neutral-700'>
            {apiKeyPrefix}xxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
          </span>
        </p>

        <Button onClick={() => setIsEditMode(true)}>Update Api Key</Button>

        {isValid === false && (
          <div className='flex justify-center'>
            <Message
              severity='warn'
              content={
                <div className='px-3 text-center'>
                  <h3 className='text-lg mb-2 font-bold'>
                    <i className='pi pi-exclamation-triangle scale-125 mr-2' /> Api Key is Not Valid
                  </h3>
                  <p>
                    <CreateApiKeyLink text='Create a new Api Key' className='underline' /> in Documint then update it
                    here.
                  </p>
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className='h-full w-full flex justify-center items-center flex-auto'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className='flex justify-center items-center bg-primary-50' style={{ height: '100vh' }}>
      <div
        className='flex flex-col bg-white gap-6 justify-start p-8 rounded-md shadow-lg'
        style={{ flexBasis: '500px', minHeight: '250px' }}
      >
        <div className='flex justify-center'>
          <Image src='https://documint.me/hubfs/New%20Documint%20Site%20Assets/graphics/logos/documint-logo.svg' />
        </div>

        {getMeta.isLoading ? renderLoading() : isEditMode ? renderEditMode() : renderDisplayMode()}

        <Toast ref={toast} position='bottom-center' />
      </div>
    </div>
  );
};

function CreateApiKeyLink({ text, className }: { text?: string; className?: string }) {
  return (
    <Link to='https://app.documint.me/settings/integrations' target='_blank' className={className}>
      {text || 'Create an API Key'}
    </Link>
  );
}

export default ApiKeyView;
