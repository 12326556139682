import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DocumentDataState,
  clearDocumentData as _clearDocumentData,
  setDocumentData as _setDocumentData,
  selectDocumentData,
} from '../../../store/documentDataSlice';

export default function useDocumentData() {
  const documentData = useSelector(selectDocumentData);
  const dispatch = useDispatch();

  const setDocumentData = useCallback(
    (data: DocumentDataState) => {
      dispatch(_setDocumentData(data));
    },
    [dispatch],
  );

  const clearDocumentData = useCallback(() => dispatch(_clearDocumentData()), [dispatch]);

  return { setDocumentData, clearDocumentData, documentData };
}
