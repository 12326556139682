import { useContext } from 'react';
import { RecordContext } from '../contexts';

export const useRecordContext = () => {
  const context = useContext(RecordContext);

  if (!context) {
    throw new Error('useRecordListContext must be used within a RecordListContextProvider');
  }

  return context;
};

export default useRecordContext;
