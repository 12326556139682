import { useCallback, useMemo } from 'react';
import { Menu } from 'primereact/menu';
import { useTokensViewContext } from '../../hooks';
import { useObjectTypes } from '../../../objectTypes';
import { TokensView } from '../../contexts/TokensViewContext';
import { ExtendedProperty, snakeCaseToTitleCase } from '../../utils';
import { Image } from 'primereact/image';
import { MenuItem } from 'primereact/menuitem';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import usePropertiesContext from '../../hooks/usePropertiesContext';
import config from '../../../../config';
import { Message } from 'primereact/message';

interface MenuItemProps {
  label: string;
  view: TokensView;
  data: ExtendedProperty[];
  active: boolean;
}

export default function TokensMenu() {
  const { setActiveProperties, associationProperties, primaryProperties, propertiesMeta } = usePropertiesContext();
  const { setTokensView, tokensView } = useTokensViewContext();
  const { selectedObjectType } = useObjectTypes();
  const isCustomObject = useMemo(() => selectedObjectType?.objectTypeId?.startsWith('2-'), [selectedObjectType]);

  // menu item for menuItems object
  const renderMenuItem = useCallback(
    ({ active, data, label, view }: MenuItemProps) => {
      if (!setActiveProperties || !setTokensView) return;

      return (
        <div
          className={
            'w-full px-[20px] py-[12px] cursor-pointer active:bg-primary-200 text-slate-500 rounded ' +
            (active ? 'bg-slate-100 text-slate-700' : 'hover:bg-slate-100 hover:text-slate-700')
          }
          onClick={() => {
            setTokensView(view);
            setActiveProperties(data);
          }}
        >
          <p className='cursor-pointer '>{label.replace(label[0], label[0].toUpperCase())}</p>
        </div>
      );
    },
    [setActiveProperties, setTokensView],
  );

  // Displayed when an object does not have any associations
  const renderNoAssociationsMessage = useCallback(() => {
    const selectedObjectTypeIsCustom = selectedObjectType?.objectTypeId?.startsWith('2-');

    return (
      <div className='flex flex-col items-center justify-center w-[85%] mx-auto pt-3'>
        {selectedObjectTypeIsCustom ? (
          <Message severity='contrast' text='Associations are not yet supported for custom objects.' />
        ) : (
          <>
            <p className='text-primary-500 text-xl text-center mb-5'>
              Looks like {selectedObjectType?.pluralLabel ? selectedObjectType.pluralLabel : 'this object'} does not
              support associations.
            </p>
            <Image
              src='https://storage.googleapis.com/hubspot-app-resources/images/associations.svg'
              width='100px'
              height='auto'
            />
            <p className='mt-4 text-center text-md'>
              See the{' '}
              <a className='text-primary-500 hover:underline' href={config.docsURL} target='_blank' rel='noreferrer'>
                help center
              </a>{' '}
              for information on how to get associated tokens.
            </p>
          </>
        )}
      </div>
    );
  }, [selectedObjectType]);

  
  // Menu items is the object that contains the structure of the menu component
  const menuItems: MenuItem[] = useMemo(() => {
    if (!primaryProperties || !associationProperties || !setActiveProperties || !setTokensView) return [];
    const recordLabel = `${selectedObjectType?.singularLabel || 'Record'} Tokens`;

    return [
      {
        // Primary menu option
        label: 'Primary',
        items: [
          {
            id: recordLabel,
            label: snakeCaseToTitleCase(recordLabel),
            template: renderMenuItem({
              label: recordLabel,
              view: 'primary',
              data: primaryProperties,
              active: tokensView === 'primary',
            }),
          },
        ],
      },
      {
        // Associations menu option
        label: 'Associations',
        items:
          // While still loading a loading indicator is present
          (propertiesMeta.isFetching || propertiesMeta.isUninitialized) && !isCustomObject
            ? [
                {
                  id: 'loading',
                  label: 'Loading...',
                  template: (
                    <AiOutlineLoading3Quarters
                      className='animate-spin text-primary-500 mt-8 mx-auto w-fit'
                      style={{ fontSize: '2.0rem' }}
                    />
                  ),
                },
              ]
            : // If there are associated properties for this object, display them
            associationProperties.length
            ? associationProperties.map((association) => ({
                id: association.name,
                template: renderMenuItem({
                  label: snakeCaseToTitleCase(association.name),
                  view: association.name,
                  data: association.relatedObjectProperties || [],
                  active: tokensView === association.name,
                }),
              }))
            : // else display a no associations message
              [
                {
                  label: 'No associations found',
                  id: 'no-associations',
                  template: renderNoAssociationsMessage(),
                },
              ],
      },
    ];
  }, [
    propertiesMeta,
    renderNoAssociationsMessage,
    primaryProperties,
    associationProperties,
    setActiveProperties,
    setTokensView,
    renderMenuItem,
    selectedObjectType,
    tokensView,
    isCustomObject,
  ]);

  return (
    <Menu
      model={menuItems}
      className='bg-transparent overflow-y-auto'
      pt={{
        root: {
          style: { padding: 0, margin: 0, border: 'none', height: '90%', overflow: 'auto', width: '100%' },
        },
        submenuHeader: {
          className: 'text-slate-600',
          style: {
            fontWeight: 'normal',
            fontSize: '1.1rem',
            background: 'transparent',
            marginLeft: 0,
            paddingLeft: 0,
          },
        },
      }}
    />
  );
}
