import { useCallback, useMemo } from 'react';
import { ListQueryParams } from '../../../app/api/types';
import useRecordListContext from './useRecordListContext';
import { initialState } from '../contexts';

type ParamSetter = <T extends ListQueryParams, K extends keyof T>(key: K, value: T[K]) => void;

export const useRecordList = () => {
  const {
    fetchRecords,
    recordsBatch,
    recordsMeta,
    params,
    setParams: _setParams,
    recordSearchValue,
    setRecordSearchValue,
  } = useRecordListContext();

  const setParam = useCallback<ParamSetter>(
    (paramName, paramValue) => _setParams((prev) => ({ ...prev, [paramName]: paramValue })),
    [_setParams],
  );
  const setParams = useCallback<(params: ListQueryParams) => void>(
    (params) => _setParams((prev) => ({ ...prev, ...params })),
    [_setParams],
  );
  const resetParams = useCallback(() => _setParams(initialState.params), [_setParams]);

  const setSort = useCallback(
    (sortBy: string | undefined, sortOrder: 'ASCENDING' | 'DESCENDING' | undefined) =>
      _setParams((prev) => ({ ...prev, sortBy, sortOrder })),
    [_setParams],
  );

  const resetSort = useCallback(
    () =>
      _setParams((prev) => ({ ...prev, sortBy: initialState.params.sortBy, sortOrder: initialState.params.sortOrder })),
    [_setParams],
  );

  return useMemo(
    () => ({
      fetchRecords,
      recordsBatch,
      recordsMeta,
      params,
      setParam,
      setParams,
      resetParams,
      setSort,
      resetSort,
      recordSearchValue,
      setRecordSearchValue,
    }),
    [
      fetchRecords,
      recordsBatch,
      recordsMeta,
      params,
      setParam,
      setParams,
      resetParams,
      setSort,
      resetSort,
      recordSearchValue,
      setRecordSearchValue,
    ],
  );
};

export default useRecordList;
