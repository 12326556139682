import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

export type DocumentDataState = {
  recordId: string | null;
  objectTypeId: string | null;
  templateId: string | null;
  documentUrl: string | null;
};

const initialState: DocumentDataState = {
  recordId: null,
  objectTypeId: null,
  templateId: null,
  documentUrl: null,
};

export const documentDataSlice = createSlice({
  name: 'documentData',
  initialState,
  reducers: {
    setDocumentData(state, action: PayloadAction<Partial<DocumentDataState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearDocumentData() {
      return initialState;
    },
  },
});

export const { setDocumentData, clearDocumentData } = documentDataSlice.actions;

export const selectDocumentData = (state: RootState) => state.documentData;

export default documentDataSlice.reducer;
