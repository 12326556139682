import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useLazyGetPropertiesQuery } from '../../../app/api/properties';

import { PropertyGroup } from '../../../app/api/properties';

import { splitPrimaryAndAssociatedProperties } from '../utils';
import { addGroupNameToProperties, ExtendedProperty, MapPropertiesToExtendedProperties } from '../utils/mappers';
import { useObjectTypes } from '../../objectTypes';

const mapPropertiesToExtendedProperties = MapPropertiesToExtendedProperties.getInstance();

interface PropertiesContextProps {
  fetchProperties: any;
  propertiesMeta: any;

  // Tracks the list of properties currently being displayed
  activeProperties: ExtendedProperty[];
  setActiveProperties: Dispatch<SetStateAction<ExtendedProperty[]>>;

  primaryProperties: ExtendedProperty[];
  associationProperties: ExtendedProperty[];

  groups: PropertyGroup[];
}

export const PropertiesContext = createContext<PropertiesContextProps | null>(null);

export default function PropertiesProvider({ children }: { children: React.ReactNode }) {
  const [activeProperties, setActiveProperties] = useState<ExtendedProperty[]>([]);

  const { selectedObjectType } = useObjectTypes();

  const [fetchQuery, queryMeta] = useLazyGetPropertiesQuery();
  const { properties, groups } = queryMeta?.data || {};

  useEffect(() => {
    if (!selectedObjectType) return;
    fetchQuery(selectedObjectType.objectTypeId || '');
  }, [fetchQuery, selectedObjectType]);

  const { primary: primaryProperties, associated: associationProperties } = useMemo(() => {
    // Return empty arrays if properties is undefined
    if (!properties) return { primary: [], associated: [] };

    // Map all of the properties to extended properties
    const extendedProperties = mapPropertiesToExtendedProperties.map(properties);

    // Split the properties into primary and associated
    const { primary, associated } = splitPrimaryAndAssociatedProperties(extendedProperties);

    // If there are no groups, return the properties as is
    if (!groups) return { primary, associated };

    // Else we add the groupName property to the primary properties
    return {
      primary: addGroupNameToProperties(groups, primary),
      associated,
    };
  }, [properties, groups]);

  // This should only be called once because the primary properties should not change
  useEffect(() => {
    setActiveProperties(primaryProperties);
  }, [primaryProperties]);

  const value: PropertiesContextProps = {
    fetchProperties: fetchQuery,
    propertiesMeta: queryMeta,
    activeProperties,
    setActiveProperties,
    groups: groups || [],
    primaryProperties,
    associationProperties,
  };

  return <PropertiesContext.Provider value={value}>{children}</PropertiesContext.Provider>;
}
