/**
 * This component displays the record the user is currently working on and allows
 * the user to change records. See Records view.
 */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { mapRecordToRecordListItem } from '../../utils';
import { useRecordsUI, useRecordContext, useRecordList } from '../../hooks';
import { useObjectTypes } from '../../../objectTypes';
import { ExpandedRecordType } from '../../../../app/api/records';
import type { DropdownChangeEvent, DropdownFilterEvent } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import useObject from '../../../../hooks/useObject';

export function RecordDropdown() {
  const { selectedObjectType } = useObjectTypes();
  const { singularLabel = 'record', pluralLabel = 'records' } = selectedObjectType || {};
  const { selectedRecord } = useRecordContext();
  const { objectId: recordId, setObjectId: setRecordId } = useObject();
  const { recordsBatch, recordSearchValue, setRecordSearchValue, recordsMeta } = useRecordList();
  const dropdownRef = useRef<Dropdown>(null);

  const { openModal } = useRecordsUI();
  const [options, setOptions] = useState<SelectItemOptionsType | []>([]);

  // We need to set the options for the dropdown in a useEffect because selectedRecord and records need to be
  // coordinated to ensure race conditions don't mess with the dropdown options.
  useEffect(() => {
    const recordData = recordsBatch?.data?.slice(0, 24);

    if (!selectedRecord) {
      setOptions(recordData.slice(0, 24).map((record: ExpandedRecordType) => mapRecordToRecordListItem(record)));
      return;
    }

    let recordOptions: ExpandedRecordType[] = [];
    if (recordData.find((record: ExpandedRecordType) => record.id === selectedRecord.id)) {
      recordOptions = recordData.filter((record: ExpandedRecordType) => record.id !== selectedRecord.id);
    } else {
      recordOptions = recordData.slice(0, 24);
    }
    recordOptions.unshift(selectedRecord);
    setOptions(recordOptions.map((record) => mapRecordToRecordListItem(record)));
  }, [recordsBatch, selectedRecord]);

  // Convert selected record to a string for the dropdown
  const value: string | undefined = useMemo(() => {
    return selectedRecord ? mapRecordToRecordListItem(selectedRecord).value : undefined;
  }, [selectedRecord]);

  // Tracks if the data is being fetched in any manner
  const gettingData = useMemo(() => {
    // The last condition indicates that the recordId is set but the selectedRecord is not
    // because it was not found in the recordsList and needs to be requested individually (see recordListContext.tsx and recordContext.tsx)
    return (
      recordsMeta?.isFetching || recordsMeta?.isLoading || recordsMeta?.isUninitialized || (recordId && !selectedRecord)
    );
  }, [recordsMeta, recordId, selectedRecord]);

  const handleSearchValueChange = useCallback(
    (searchString: string | undefined) => {
      setRecordSearchValue(searchString || '');
    },
    [setRecordSearchValue],
  );

  /* Dropdown for the record controls */
  return (
    <>
      <Dropdown
        ref={dropdownRef}
        value={value}
        className='mb-0'
        filter
        onFilter={(e: DropdownFilterEvent) => handleSearchValueChange(e.filter)}
        emptyMessage={
          recordsMeta.isError
            ? // If there are no options because of an error, display an error message
              `Error fetching ${selectedObjectType?.pluralLabel?.toLowerCase()}. Try refreshing the page.`
            : gettingData
            ? // Loading options needs to work in conjunction with clearing options after each objectType change
              'Loading options...'
            : 'No available options'
        }
        options={options}
        loading={gettingData}
        placeholder={gettingData ? `Loading ${pluralLabel}` : `Select a ${singularLabel}`}
        onChange={(e: DropdownChangeEvent) => setRecordId(e.target.value?.id)}
        required
        panelFooterTemplate={() => (
          <Button
            text
            iconPos='right'
            onClick={() => {
              dropdownRef.current?.hide();
              openModal();
            }}
            icon='pi pi-search'
            label='Advanced Search'
            size='small'
          />
        )}
        pt={{
          emptyMessage: { style: { color: recordsMeta.isError ? 'red' : 'black' } },
          filterInput: { value: recordSearchValue },
          footer: { className: 'flex justify-center px-3 py-3 border-t border-gray-200' },
          panel: { style: { maxWidth: '10rem' } },
        }}
      />
      {!selectedRecord && !gettingData && (
        // Mimic the appearance of prime react button layout (plus some padding on the right)
        <Message
          pt={{
            root: {
              className: 'flex !items-center pl-[20px] pr-[30px] text-center',
            },
            icon: {
              className: 'basis-initial',
            },
            text: {
              className: 'basis-full',
            },
          }}
          severity={recordsMeta.isError ? 'error' : 'info'}
          text={
            recordsMeta.isError
              ? `Error fetching ${selectedObjectType?.pluralLabel?.toLowerCase() || 'records'}`
              : `Select a ${selectedObjectType?.singularLabel?.toLowerCase() || 'record'} to continue`
          }
        />
      )}
    </>
  );
}

export default RecordDropdown;
