import { useContext } from 'react';

import { TokensViewContext } from '../contexts/TokensViewContext';

export default function useTokensContext() {
  const context = useContext(TokensViewContext);

  if (!context) {
    throw new Error('useTokensViewContext must be used within a TokensViewProvider');
  }

  return context;
}
