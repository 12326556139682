import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectObjectId,
  selectObjectType,
  selectObject,
  removeObject as _removeObject,
  setObject as _setObject,
  removeObjectType as _removeObjectType,
  setObjectType as _setObjectType,
  removeObjectId as _removeObjectId,
  setObjectId as _setObjectId,
} from '../store/objectSlice';
import type { ObjectSliceState } from '../store/objectSlice';

export default function useObject() {
  const objectType = useSelector(selectObjectType);
  const objectId = useSelector(selectObjectId);
  const object = useSelector(selectObject);

  const dispatch = useDispatch();

  const setObject = useCallback((object: ObjectSliceState) => dispatch(_setObject(object)), [dispatch]);
  const removeObject = useCallback(() => dispatch(_removeObject()), [dispatch]);
  const setObjectType = useCallback((objectType: string) => dispatch(_setObjectType(objectType)), [dispatch]);
  const removeObjectType = useCallback(() => dispatch(_removeObjectType()), [dispatch]);
  const setObjectId = useCallback((objectId: string) => dispatch(_setObjectId(objectId)), [dispatch]);
  const removeObjectId = useCallback(() => dispatch(_removeObjectId()), [dispatch]);

  return useMemo(
    () => ({
      objectType,
      objectId,
      object,
      setObject,
      removeObject,
      setObjectType,
      removeObjectType,
      setObjectId,
      removeObjectId,
    }),
    [
      objectType,
      objectId,
      object,
      setObject,
      removeObject,
      setObjectType,
      removeObjectType,
      setObjectId,
      removeObjectId,
    ],
  );
}
