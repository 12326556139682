import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useCreateDocumentMutation, UseCreateDocumentMutation } from '../../../app/api/documents';
import { useTemplates } from '../../templates';
import { useObjectTypes } from '../../objectTypes';
import useDocumentData from '../hooks/useDocumentData';
import useObject from '../../../hooks/useObject';

type DocumentContextType = {
  createDocument: ReturnType<UseCreateDocumentMutation>[0];
  documentUrl: string | undefined;
  documentMeta: ReturnType<UseCreateDocumentMutation>[1];
};

export const documentContext = createContext<DocumentContextType | null>(null);

export function DocumentProvider({ children }: { children: ReactNode }) {
  const [documentUrl, setDocumentUrl] = useState<string | undefined>(undefined);

  const [_createDocument, meta] = useCreateDocumentMutation();
  const { documentData } = useDocumentData();

  const { objectId: recordId } = useObject();
  const { selectedObjectType } = useObjectTypes();
  const { selectedTemplate } = useTemplates();

  useEffect(() => {
    const { objectTypeId: storedObjectTypeId, recordId: storedRecordId, templateId: storedTemplateId } = documentData;

    if (!recordId || !selectedObjectType || !selectedTemplate) {
      setDocumentUrl(undefined);
      return;
    }

    if (
      recordId === storedRecordId &&
      (selectedObjectType.objectTypeId === storedObjectTypeId ||
        selectedObjectType.objectTypeName === storedObjectTypeId) &&
      selectedTemplate.id === storedTemplateId &&
      documentData.documentUrl
    ) {
      setDocumentUrl(documentData.documentUrl);
      return;
    }

    _createDocument({ templateId: selectedTemplate.id, objectType: selectedObjectType.objectTypeId, recordId });
  }, [recordId, selectedObjectType, selectedTemplate, _createDocument, documentData]);

  const value: DocumentContextType = useMemo(
    () => ({
      createDocument: _createDocument,
      documentUrl,
      documentMeta: meta,
    }),
    [_createDocument, documentUrl, meta],
  );

  return <documentContext.Provider value={value}>{children}</documentContext.Provider>;
}

export default DocumentProvider;
