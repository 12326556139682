import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { useTokensViewContext } from '../../hooks';

export default function TokenSearchInput() {
  const { tokenSearchValue, setTokenSearchValue } = useTokensViewContext();

  return (
    <IconField iconPosition='left' className='w-full'>
      {tokenSearchValue !== '' ? (
        <InputIcon className='pi pi-times' onClick={() => setTokenSearchValue('')}></InputIcon>
      ) : (
        <InputIcon className='pi pi-search'></InputIcon>
      )}
      <InputText
        className='p-inputtext-md w-full'
        placeholder='Search Tokens'
        type='text'
        value={tokenSearchValue || ''}
        onChange={(e) => setTokenSearchValue(e.target.value)}
      />
    </IconField>
  );
}
