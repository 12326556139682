import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

// Object type is the objectTypeId or objectTypeName for the selected object, and objectId is the recordId for the selected record.
export type ObjectSliceState = { objectId: string | null; objectType: string | null };

const initialState: ObjectSliceState = { objectId: null, objectType: null };

export const objectSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    setObject(state, action: PayloadAction<ObjectSliceState>) {
      state.objectId = action.payload.objectId;
      if (action.payload.objectType) {
        state.objectType = action.payload.objectType;
      }
    },

    removeObject(state) {
      state.objectId = null;
      state.objectType = null;
    },

    setObjectId(state, action: PayloadAction<ObjectSliceState['objectId']>) {
      state.objectId = action.payload;
    },

    removeObjectId(state) {
      state.objectId = null;
    },

    setObjectType(state, action: PayloadAction<ObjectSliceState['objectType']>) {
      state.objectType = action.payload;
    },

    removeObjectType(state) {
      state.objectType = null;
    },
  },
});

export const { setObject, removeObject, setObjectId, removeObjectId, setObjectType, removeObjectType } =
  objectSlice.actions;

export const selectObject = (state: RootState) => state.object;

export const selectObjectId = (state: RootState) => state.object.objectId;

export const selectObjectType = (state: RootState) => state.object.objectType;

export default objectSlice.reducer;
