import { useContext } from 'react';

import { PropertiesContext } from '../contexts/PropertiesContext';

export default function usePropertiesContext() {
  const context = useContext(PropertiesContext);

  if (!context) {
    throw new Error('usePropertiesContext must be used within a TokensProvider');
  }

  return context;
}
