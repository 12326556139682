import { Link, useNavigate } from 'react-router-dom';
import { PropertiesProvider, TokensMenu, TokensViewProvider, TokenTable, ViewControls } from '../../features/tokens';
import config from '../../config';
import { Button } from 'primereact/button';

export function TokensPage() {
  const navigate = useNavigate();
  return (
    <div className='w-full h-screen flex flex-col pt-2'>
      {/* body */}
      <div className='flex w-full h-full'>
        {/* dashboard. contains search, tokens menu, and help */}
        <div className='w-[15%] flex flex-col justify-stretch items-start h-full gap-2 min-w-[225px] max-w-[250px]'>
          <div className='flex items-center justify-between w-full'>
            <Button
              size='small'
              className='px-2 -ml-2'
              text
              tooltip='Back'
              tooltipOptions={{ showDelay: 1000 }}
              icon={<i className='pi pi-arrow-left' />}
              onClick={() => navigate('/dashboard')}
            />
            <h4 className='text-xl font-medium text-slate-600'>Tokens</h4>
            <div className='w-16'></div>
          </div>

          <div className='w-[90%] h-[1px] bg-slate-200 self-center' />

          {/* search input and menu */}
          <div className='flex flex-col w-full gap-2 h-[90%]'>
            {/* menu */}
            <TokensMenu />
          </div>

          {/* help link */}
          <div className='w-full flex basis-0 justify-center'>
            <Link to={config.docsURL} target='_blank' className='p-button p-button-text'>
              <i className='pi pi-compass mr-2' />
              Help Center
            </Link>
          </div>
        </div>

        {/* divider */}
        <div className='w-[2px] h-full ml-4' />

        <div className='flex flex-col basis-full h-full bg-primary-50 p-4'>
          {/* view controls */}
          <div className='basis-0'>
            <ViewControls />
          </div>

          {/* token list */}
          <div className='basis-full h-full overflow-auto'>
            <TokenTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Wrapper() {
  return (
    <PropertiesProvider>
      <TokensViewProvider>
        <TokensPage />
      </TokensViewProvider>
    </PropertiesProvider>
  );
}
