import { useMemo, useRef } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useTokensViewContext } from '../../hooks';
import usePropertiesContext from '../../hooks/usePropertiesContext';

export default function GroupDropdown() {
  const { groups } = usePropertiesContext();
  const { setActiveGroup, activeGroup } = useTokensViewContext();
  const dropdownRef = useRef<Dropdown>(null);

  const options = useMemo(() => {
    if (!groups) return [];
    const options: { label: string; value: string }[] = [];

    groups.forEach((group) => {
      if (group.name === 'Associations' || group.id === 'associations') return;
      else
        options.push({
          label: group.name,
          value: group.name,
        });
    });

    return options;
  }, [groups]);

  return (
    <div className='flex items-center gap-1'>
      <Dropdown
        options={options || []}
        style={{ maxWidth: '12rem' }}
        value={activeGroup}
        placeholder='Select'
        onChange={(e: DropdownChangeEvent) => setActiveGroup?.(e.value)}
        tooltip='Groups are used to organize properties into categories.'
        tooltipOptions={{ showDelay: 2000, position: 'bottom' }}
        ref={dropdownRef}
      />
      {activeGroup && (
        <i className='pi pi-times cursor-pointer inline-block p-2 text-gray-400' onClick={() => dropdownRef.current?.clear()}></i>
      )}
    </div>
  );
}
