import { Column } from 'primereact/column';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import TokenTableColumnBody from './TokenTableColumnBody';
import renderTypeColumnBody from './renderTypeColumnBody';
import renderDefinedByColumnBody from './renderDefinedByColumnBody';

import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { useCallback, useMemo } from 'react';
import Icon from '../../../../components/InfoIcon';
import { useTokensViewContext } from '../../hooks';
import { FilterMatchMode } from 'primereact/api';
import usePropertiesContext from '../../hooks/usePropertiesContext';

const TokenTable = () => {
  const { activeProperties, propertiesMeta } = usePropertiesContext();
  const { useType, tokensView, tokenSearchValue, definedBy, activeGroup } = useTokensViewContext();

  // Filters for the DataTable
  const filters: DataTableFilterMeta = useMemo(() => {
    return {
      global: { value: tokenSearchValue, matchMode: FilterMatchMode.CONTAINS },
      isCustom: {
        value: definedBy === 'all' ? [true, false] : definedBy === 'hubspot' ? [false] : [true],
        matchMode: FilterMatchMode.IN,
      },
      groupName: { value: tokensView === 'primary' ? activeGroup : null, matchMode: FilterMatchMode.EQUALS },
    };
  }, [tokenSearchValue, definedBy, activeGroup, tokensView]);

  // Renders the header for the token column. Used to display the association name when looping over associated properties
  const renderTokenHeader = useCallback(() => {
    if (tokensView === 'primary' || useType === 'first') {
      return <p>Tokens</p>;
    } else {
      const associationName = activeProperties[0]?.parent?.name || '';
      const tooltipForAssociationName = `Use '${associationName}' as the list variable when looping/repeating an element in your template.
                      See Instructions for more details.`;
      return (
        <div className='flex items-center gap-2'>
          <div className=' bg-sky-50 p-1 px-2 border-2 border-sky-150 rounded-md'>
            <p>{associationName}</p>
          </div>
          <Icon id={associationName} key={associationName} tooltip={tooltipForAssociationName} />
        </div>
      );
    }
  }, [tokensView, useType, activeProperties]);

  if (propertiesMeta.isLoading) {
    return (
      <div className='flex h-full w-full justify-center items-center' style={{ height: 'calc(100vh - 220px)' }}>
        <LoadingSpinner text='Loading properties' />
      </div>
    );
  }

  return (
    <DataTable
      value={activeProperties}
      // virtual scrolling
      scrollable
      virtualScrollerOptions={{
        itemSize: 82,
      }}
      scrollHeight='100vh'
      // filters
      filters={filters}
      globalFilterFields={['label', 'name', 'groupName']}
      filterDisplay='menu'
      // misc
      loading={propertiesMeta.isLoading}
      stripedRows
      style={{ height: '100%', overflow: 'hidden' }}
      
      pt={{
        bodyRow: { style: { height: '82px' } },
        loadingOverlay: { style: { height: '100%', backgroundColor: 'rgba(255,255,255,.7)' } },
        column: { headerCell: { className:'bg-slate-200 text-slate-600' } },
      }}
    >
      <Column field='label' header='Name' className='overflow-x-hidden text-ellipsis' sortable />
      <Column
        field='name'
        header={renderTokenHeader}
        body={(property) => <TokenTableColumnBody property={property} />}
      />
      {tokensView === 'primary' && <Column field='groupName' header='Group' sortable />}
      <Column field='type' header='Type' body={renderTypeColumnBody} />
      <Column field='isCustom' header='' style={{ width: '70px' }} body={renderDefinedByColumnBody} />
    </DataTable>
  );
};

export default TokenTable;
