import { useCallback, useState } from 'react';
import { ExpandedRecordType } from '../../../app/api/records';

// This hook is used to manage a list of records, preventing duplicates and clearing the list when a record with a different object type is added.
export function useRecordListManager() {
  const [records, setRecords] = useState<ExpandedRecordType[]>([]);

  // This method is used to add a record to the records list without duplicates
  const addToRecords = useCallback(
    (records: ExpandedRecordType[]) => {
      setRecords((prev) => {
        if (records.length === 0) return prev;

        // If the previous object type is different from the current object type, we return the new records (clearing the old ones)
        if (prev[0]?.objectTypeId !== records[0]?.objectTypeId) {
          return records;
        }

        // Otherwise, we add the new records to the list without duplicates
        return [...prev, ...records.filter((record) => !prev.some((prevRecord) => prevRecord.id === record.id))];
      });
    },
    [setRecords],
  );

  const clearRecords = useCallback(() => {
    setRecords([]);
  }, [setRecords]);

  return { records, addToRecords, clearRecords };
}

export default useRecordListManager