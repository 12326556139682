import { ListResponse, SingleResponse } from './types';

export const getCurrentParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const result: any = {};
  //@ts-ignore
  for (let [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};

export const TYPE_MISMATCH_ERROR = 'Server response does not match expected type';

export function transformListResponse<T>(response: ListResponse<T>): ListResponse<T> {
  if (!response.data) throw new Error(TYPE_MISMATCH_ERROR);
  return response;
}

export function transformSingleResponse<T>(response: SingleResponse<T>): T {
  if (!response.data) throw new Error(TYPE_MISMATCH_ERROR);
  return response.data;
}
