import { apiSlice } from '.';
import type { ListQueryParams, ListResponse } from './types';
import { transformListResponse, transformSingleResponse } from './utils';

const RECORDS_URL = 'records';

// ExpandedRecordType is copied from the server and expands HS's object with additional properties provided by its object type schema.
export interface ExpandedRecordType {
  archived: boolean;
  createdAt: string;
  id: string; // The unique record ID
  properties: {
    [key: string]: any;
  };
  updatedAt: string;
  primaryDisplayProperty: string;
  secondaryDisplayProperties: {
    [key: string]: string | null;
  };
  objectTypeLabels: {
    plural: string; // e.g. 'Contacts', 'Companies', 'Deals'
    singular: string; // e.g. 'Contact', 'Company', 'Deal'
  };
  objectTypeName: string; // e.g. 'CONTACT', 'COMPANY', 'DEAL'
  objectTypeId: string; // e.g. '0-1', '0-2', '0-3'
  searchableProperties: string[]; // a list of properties that be used to use HS's search functionality
}

export interface RecordSingleQueryParams {
  properties?: string[];
}

export interface RecordSingleQueryArgs {
  objectType: string;
  recordId: string;
  queryParams?: RecordSingleQueryParams;
}

export type RecordListQueryResponse = ListResponse<ExpandedRecordType>;

export const recordsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecord: builder.query<ExpandedRecordType, RecordSingleQueryArgs>({
      query: (args) => ({
        url: `/${RECORDS_URL}/single/${args.objectType}/${args.recordId}`,
        params: args.queryParams,
      }),
      providesTags: ['Record'],
      transformResponse: transformSingleResponse<ExpandedRecordType>,
    }),

    getDefaultRecord: builder.query<ExpandedRecordType, void>({
      query: () => `/${RECORDS_URL}/single`,
      providesTags: ['Record'],
      transformResponse: transformSingleResponse<ExpandedRecordType>,
    }),

    getRecords: builder.query<RecordListQueryResponse, { objectType: string; queryParams: ListQueryParams }>({
      query: (args) => ({
        url: `/${RECORDS_URL}/list/${args.objectType}`,
        params: args.queryParams,
      }),
      transformResponse: transformListResponse<ExpandedRecordType>,
      providesTags: (response) =>
        response
          ? [
              { id: 'LIST', type: 'Records' as const },
              ...response?.data?.map(({ id }) => ({ id, type: 'Records' as const })),
            ]
          : [{ id: 'LIST', type: 'Records' as 'Records' }],
    }),
  }),
});

export const {
  useGetRecordQuery,
  useGetRecordsQuery,
  useLazyGetRecordQuery,
  useLazyGetRecordsQuery,
  useLazyGetDefaultRecordQuery,
  useGetDefaultRecordQuery,
} = recordsApi;
