import { createContext, useCallback, useEffect, useState } from 'react';
import { ExpandedRecordType, useLazyGetRecordQuery } from '../../../app/api/records';
import useRecordListContext from '../hooks/useRecordListContext';
import useObject from '../../../hooks/useObject';

export interface RecordContextType {
  selectedRecord: ExpandedRecordType | null;
}

export const RecordContext = createContext<RecordContextType | null>(null);

export function RecordProvider({ children }: { children: React.ReactNode }) {
  const { objectId: recordId } = useObject();
  const [selectedRecord, setSelectedRecord] = useState<ExpandedRecordType | null>(null);
  const { allRecords, addToAllRecords } = useRecordListContext();

  const { objectType } = useObject();
  const [getRecord] = useLazyGetRecordQuery();

  // Adds a requested record to the list of all records
  const addRecordToList = useCallback(
    async (recordId: string, objectType: string, records: ExpandedRecordType[]) => {
      try {
        const record = await getRecord({ recordId, objectType }).unwrap();
        if (!record) throw new Error('Error getting record');
        addToAllRecords([record]);
      } catch (error) {
        console.error('Error getting record', error);
      }
    },
    [getRecord, addToAllRecords],
  );

  // Finds the records from the list of records or requests one to be added.
  useEffect(() => {
    // If not object type or no records, then return
    if (!objectType || !allRecords.length) return;

    // If not recordId, then clear the selected record
    if (!recordId) {
      setSelectedRecord(null);
      return;
    }

    const record = allRecords.find((record) => record.id === recordId);

    // The recordId may not be in the list so we need to request it if it is not
    // If the record was in the list, then set it
    if (record) {
      setSelectedRecord(record);
    } else {
      // The record is not in the list so we need to request it
      // We will cause this effect to run again when the record is added to the list
      addRecordToList(recordId, objectType || '', allRecords);
    }
  }, [recordId, setSelectedRecord, objectType, addRecordToList, allRecords]);

  return <RecordContext.Provider value={{ selectedRecord }}>{children}</RecordContext.Provider>;
}

export default RecordProvider;
