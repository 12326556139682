import { Tooltip } from 'primereact/tooltip';

interface IconProps {
  id: string;
  tooltip: string | void;
}

export default function InfoIcon({ id, tooltip }: IconProps) {
  if (!tooltip) return null;

  const tooltipId = `tooltip-${id}`;

  return (
    <>
      <Tooltip target={`#${tooltipId}`} className='z-50' />
      <i
        id={tooltipId}
        className='pi pi-info-circle mx-2'
        style={{ fontSize: '1rem' }}
        data-pr-tooltip={tooltip}
        data-pr-position={'bottom'}
        data-pr-showdelay={500}
      />
    </>
  );
}
