import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { useCallback } from 'react';

interface InstructionData {
  header: string;
  subheader: string;
  content: { text?: string; imageHref?: string; caption?: string }[];
}

const instructions: InstructionData[] = [
  {
    header: 'Displaying a single value',
    subheader: `You can display the value for a specific record in a list of associated records. This is useful when an associations list only contains a single associated record.`,
    content: [
      {
        text: '1. To display values from a single record use the following syntax in your template:',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/token-syntax-example.png',
        caption: 'Notice: you can replace 0 with any number if you know the position of the item you want to display.',
      },
      {
        text: '2. Select "First item" from the "use type" dropdown then copy the associated object property token you want to use.',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/copy-first-item-property-updated.png',
      },
      {
        text: '3. Paste the token into your template',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/paste-first-item-property.png',
      },
    ],
  },
  {
    header: 'Displaying all values for an associations list',
    subheader: 'You can display values for each associated record by looping over each item in the list.',
    content: [
      {
        text: '1. Select "all items" from "use type" dropdown and Copy the association list token that appears at the top of the table.',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/copy-loop-token-updated.png',
        caption: 'Notice the association list token is not wrapped in curly braces.',
      },
      {
        text: '2. In your Documint template, select the parent element that will be repeated for each item in the list then click "Edit logic"',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/all-items-properties-setup.png',
      },
      {
        text: '3. Paste the association list token into the "List variable" field.',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/all-items-properties-setup-2.png',
      },
      {
        text: "4. Copy one of the associated object's properties from the Hubspot app.",
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/copy-sub-property-updated.png',
      },
      {
        text: '5. Paste it inside the repeating element in your template.',
        imageHref: 'https://storage.googleapis.com/hubspot-app-resources/images/paste-associated-object-properties.png',
      },
    ],
  },
];

export default function AssociationInstructions({ visible, hide }: { visible: boolean; hide: () => void }) {
  const renderHeaderTemplate = useCallback((header: string, subheader: string) => {
    return (
      <p>
        <span className='text-lg text-black'>{header}: </span>
        <span className='text-md font-normal text-black ml-1'>{subheader}</span>
      </p>
    );
  }, []);

  return (
    <Dialog header='Instructions' visible={visible} onHide={hide} style={{ maxWidth: '900px', height: '90%' }}>
      <div className='w-[95%] mx-auto'>
        <p className='mb-4 text-lg'>
          Associations are lists/arrays of records for related object types. For example: the contacts object type is
          related to contacts, companies, deals, and tickets. Each association list contains data for all the associated
          records of that object type (e.g. all associated contacts, all associated companies). You can display the
          values for associations by looping over each item, or you can just display a singe item from the list.
        </p>

        <Accordion style={{ gap: 10 }} activeIndex={0}>
          {instructions.map((instruction, index) => (
            <AccordionTab
              key={`instruction-${index}`}
              headerTemplate={() => renderHeaderTemplate(instruction.header, instruction.subheader)}
            >
              {instruction.content.map((content, index) => (
                <div key={`step-${index}`}>
                  {content.text && <p>{content.text}</p>}
                  {content.imageHref && <Image className='mt-4' preview src={content.imageHref} />}
                  {content.caption && <p className='text-sm text-gray-500 mt-2'>{content.caption}</p>}
                  {index !== instruction.content.length - 1 && <div className='bg-slate-300 h-[1px] w-full my-12' />}
                </div>
              ))}
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </Dialog>
  );
}
