import { Property, PropertyGroup } from '../../../app/api/properties';

export interface ExtendedProperty extends Property {
  key: string;
  parent: Property | undefined;
  relatedObjectProperties?: ExtendedProperty[] | undefined;
  groupName: string | undefined;
}

export class MapPropertiesToExtendedProperties {
  private static _instance: MapPropertiesToExtendedProperties;
  protected relatedProperties: ExtendedProperty[];

  private constructor() {
    this.relatedProperties = [];
  }

  public static getInstance(): MapPropertiesToExtendedProperties {
    if (!MapPropertiesToExtendedProperties._instance)
      MapPropertiesToExtendedProperties._instance = new MapPropertiesToExtendedProperties();
    return MapPropertiesToExtendedProperties._instance;
  }

  // Map properties to extended properties
  public map(properties: Property[]): ExtendedProperty[] {
    this.relatedProperties = properties.map((property) => {
      if (!property.relatedObjectProperties?.length) {
        return this.mapObjectToExtendedProperty(property);
      }
      return this.MapAssociationsToExtendedProperty(property);
    });
    return this.relatedProperties;
  }

  // Maps an object property to an extended property
  private mapObjectToExtendedProperty(property: Property): ExtendedProperty {
    const ExtendedProperty: ExtendedProperty = {
      ...property,
      key: property.name,
      parent: undefined,
      relatedObjectProperties: undefined,
      groupName: undefined,
    };
    return ExtendedProperty;
  }

  // Maps associations to extended properties
  private MapAssociationsToExtendedProperty(property: Property): ExtendedProperty {
    let childProperties: ExtendedProperty[] = [];
    property.relatedObjectProperties?.forEach((childProperty) => {
      childProperties.push({
        ...childProperty,
        key: `${property.name}-${childProperty.name}`,
        parent: { ...property, relatedObjectProperties: undefined },
        relatedObjectProperties: undefined,
        groupName: undefined,
      });
    });
    return {
      ...property,
      relatedObjectProperties: childProperties,
      key: property.name,
      parent: undefined,
      groupName: undefined,
    };
  }
}

// Add group name to properties for a more readable group name.
export function addGroupNameToProperties(
  groups: PropertyGroup[] = [],
  properties: ExtendedProperty[],
): ExtendedProperty[] {
  return properties.map((property) => {
    if (!property.groupId) return property;

    const group = groups.find((group) => group.id === property.groupId);
    if (!group) return property;

    return { ...property, groupName: group?.name };
  });
}