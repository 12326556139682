import { createContext, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ExpandedRecordType, RecordListQueryResponse, useLazyGetRecordsQuery } from '../../../app/api/records';
import type { Dispatch } from 'react';
import { ListQueryParams } from '../../../app/api/types';
import { useDebounce } from 'primereact/hooks';
import { useRecordListManager } from '../hooks';
import useObject from '../../../hooks/useObject';

export interface RecordListContextType {
  fetchRecords: ReturnType<typeof useLazyGetRecordsQuery>[0];
  recordsBatch: RecordListQueryResponse;
  recordsMeta: ReturnType<typeof useLazyGetRecordsQuery>[1];
  allRecords: ExpandedRecordType[];
  addToAllRecords: (records: ExpandedRecordType[]) => void;
  clearAllRecords: () => void;
  params: ListQueryParams;
  setParams: Dispatch<SetStateAction<ListQueryParams>>;
  recordSearchValue?: string;
  setRecordSearchValue: Dispatch<SetStateAction<string>>;
}

export const initialState: RecordListContextType = {
  fetchRecords: (async () => ({ data: undefined })) as unknown as ReturnType<typeof useLazyGetRecordsQuery>[0],
  recordsMeta: { data: undefined, error: undefined, isLoading: false, isFetching: false, isSuccess: false },
  recordsBatch: { data: [] },
  params: { limit: 25, after: 0, sortBy: undefined, sortOrder: 'ASCENDING' },
  allRecords: [],
  addToAllRecords: () => {},
  clearAllRecords: () => {},
  setParams: () => {},
  recordSearchValue: '',
  setRecordSearchValue: () => {},
};

export const RecordListContext = createContext<RecordListContextType>(initialState);

export function RecordListProvider({ children }: { children: React.ReactNode }) {
  const [recordsBatch, setRecordsBatch] = useState<RecordListQueryResponse>({ data: [] });
  const [fetchRecords, recordsMeta] = useLazyGetRecordsQuery();
  const [params, setParams] = useState<ListQueryParams>(initialState.params);
  const { objectType } = useObject();

  const [recordSearchValue, debouncedRecordSearchValue, debouncedSetRecordSearchValue] = useDebounce<string>('', 500);

  // Manages the all records list, preventing duplicates and clears the list when a record with a different object type is added.
  const { records: allRecords, addToRecords: addToAllRecords, clearRecords: clearAllRecords } = useRecordListManager();

  // Updates params with the new debounced search value.
  useEffect(() => {
    setParams((prev) => ({ ...prev, searchValue: debouncedRecordSearchValue || '' }));
  }, [debouncedRecordSearchValue, setParams]);

  // Fetch records is separated out into a function so that it can handle errors and return an empty array if necessary
  // RTKQ will get the error but just return the old list of records.
  const handleFetchRecords = useCallback(
    async (objectType: string, params: ListQueryParams) => {
      try {
        const records = await fetchRecords({ objectType, queryParams: params }).unwrap();
        setRecordsBatch(records || { data: [] });
        addToAllRecords(records?.data || []);
      } catch (error) {
        console.error(error);
        setRecordsBatch({ data: [] });
      }
    },
    [fetchRecords, setRecordsBatch, addToAllRecords],
  );

  // Gets a new batch of records when the object type or params change
  useEffect(() => {
    // Do not request records if searchValue in not in the params
    // searchValue is at first undefined, so we wait for it to be defined to prevent unnecessary requests
    if (!objectType || !('searchValue' in params)) return;

    handleFetchRecords(objectType, params);
  }, [objectType, params, handleFetchRecords, clearAllRecords]);

  return (
    <RecordListContext.Provider
      value={{
        fetchRecords,
        recordsBatch,
        recordsMeta,
        allRecords,
        addToAllRecords,
        clearAllRecords,
        params,
        setParams,
        recordSearchValue,
        setRecordSearchValue: debouncedSetRecordSearchValue,
      }}
    >
      {children}
    </RecordListContext.Provider>
  );
}

export default RecordListProvider;
