import { SelectButton } from 'primereact/selectbutton';
import React from 'react';
import { useTokensViewContext } from '../../hooks';

const DefinedBySelect = () => {
  const { definedBy, setDefinedBy } = useTokensViewContext();

  const fieldDefinitionOptions: { value: string; name: string }[] = [
    { value: 'all', name: 'All' },
    { value: 'hubspot', name: 'Hubspot' },
    { value: 'user', name: 'Me' },
  ];

  return (
    <SelectButton
      value={definedBy || 'all'}
      options={fieldDefinitionOptions}
      optionLabel='name'
      onChange={(e) => setDefinedBy(e.value)}
      tooltip='Defined by specifies who created the property.'
      tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
    />
  );
};

export default DefinedBySelect;
