import Icon from '../../../../components/InfoIcon';
import type { ExtendedProperty } from '../../utils';

const renderTypeColumnBody = (property: ExtendedProperty) => {
  let tooltip;

  if (property.type === 'enumeration') tooltip = 'Enumeration is an array (list) of objects (group of properties)';
  if (property.type === 'bool') tooltip = 'Boolean is a data type that denotes either true or false';
  return (
    <>
      {property.type} <Icon id={`${property.key}-2`} tooltip={tooltip} />
    </>
  );
};

export default renderTypeColumnBody;
