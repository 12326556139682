import { apiSlice } from '.';
import { transformSingleResponse } from './utils';

export interface Property {
  name: string;
  label: string;
  isCustom?: boolean;
  type: string;
  // If this is a list of associated properties, this will be identified by the associated object type (e.g. '0-1'). And the relatedObjectProperties will be the properties of the associated object type.
  relatedObjectType?: string;
  groupId?: string; // Used to match properties to groups
  // If this is a list of associated properties, this will be the contents of the associated object property parent/container.
  relatedObjectProperties?: Property[];
  fieldType?: string;
}

export interface PropertyGroup {
  id: string;
  name: string;
  order: number;
  archived: boolean;
}

export interface GetPropertiesResponse {
  properties: Property[];
  groups: PropertyGroup[];
}

const properties = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<GetPropertiesResponse, string>({
      query: (objectType) => `/properties/${objectType}`,
      providesTags: [{ id: 'List', type: 'Properties' }],
      transformResponse: transformSingleResponse<GetPropertiesResponse>,
    }),
  }),
});

export const { useGetPropertiesQuery, useLazyGetPropertiesQuery } = properties;
