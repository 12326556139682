import { apiSlice } from '.';
import { setDocumentData } from '../../store/documentDataSlice';

import type { Template } from './templates';
import { SingleResponse } from './types';

export type Document = {
  id: string;
  url: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  account: string;
};

export interface CreateDocumentParams {
  recordId: string;
  objectType: string;
  templateId: Template['id'];
  isLive?: boolean;
}

const documentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<Document, CreateDocumentParams>({
      query: ({ recordId, templateId, isLive = false, objectType }) => ({
        url: `/templates/${templateId}/${objectType}/${recordId}`,
        method: 'POST',
        params: isLive ? { active: true } : { preview: true },
      }),
      transformResponse: (response: SingleResponse<Document>) => response.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setDocumentData({
              documentUrl: data.url || null,
              objectTypeId: args.objectType || null,
              templateId: args.templateId || null,
              recordId: args.recordId || null,
            }),
          );
        } catch {}
      },
    }),
  }),
});

export const { useCreateDocumentMutation } = documentApi;

export type UseCreateDocumentMutation = typeof useCreateDocumentMutation;
