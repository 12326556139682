import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

import { useDebounce } from 'primereact/hooks';

export type TokensView = 'primary' | string;
export type UseType = 'first' | 'loop';
export type DefinedBy = 'all' | 'hubspot' | 'user';

interface TokensViewContextProps {
  // Search value for all property groups
  tokenSearchValue: string;
  debouncedTokenSearchValue: string;
  setTokenSearchValue: Dispatch<SetStateAction<string>>;

  // tracks the active group (primary or an associated group). e.g. 'primary', 'deals', 'contacts' etc.
  tokensView: TokensView;
  setTokensView: Dispatch<SetStateAction<TokensView>>;

  // Tracks if the user wants to loop over associated properties or use the first related property
  useType: UseType;
  setUseType: Dispatch<SetStateAction<UseType>>;

  isFormatted: boolean;
  setIsFormatted: Dispatch<SetStateAction<boolean>>;

  definedBy: DefinedBy;
  setDefinedBy: Dispatch<SetStateAction<DefinedBy>>;

  // Tracks the selected groupId user want to filter by
  activeGroup: string | null;
  setActiveGroup: Dispatch<SetStateAction<string | null>>;

  resetTokensView: () => void;
}

export const TokensViewContext = createContext<TokensViewContextProps | null>(null);

export default function TokensViewProvider({ children }: { children: React.ReactNode }) {
  const [tokenSearchValue, debouncedTokenSearchValue, setTokenSearchValue] = useDebounce<string>('', 300);

  const [tokensView, setTokensView] = useState<TokensView>('primary');
  const [useType, setUseType] = useState<UseType>('first');
  const [isFormatted, setIsFormatted] = useState<boolean>(false);
  const [definedBy, setDefinedBy] = useState<DefinedBy>('all');
  const [activeGroup, setActiveGroup] = useState<string | null>(null);

  function resetTokensView() {
    setTokenSearchValue('');
    setTokensView('primary');
    setUseType('first');
    setIsFormatted(false);
    setDefinedBy('all');
    setActiveGroup(null);
  }

  const value: TokensViewContextProps = {
    tokenSearchValue,
    debouncedTokenSearchValue,
    setTokenSearchValue,
    tokensView,
    setTokensView,
    useType,
    setUseType,
    isFormatted,
    setIsFormatted,
    definedBy,
    setDefinedBy,
    activeGroup,
    setActiveGroup,
    resetTokensView,
  };

  return <TokensViewContext.Provider value={value}>{children}</TokensViewContext.Provider>;
}
